import React from "react";

export const BoltIcon = (props: any) => (
  <svg width={8} height={13} fill="none" {...props}>
    <path
      d="M7.21 0L0 7.392h2.674L.619 13 8 5.608H5.326L7.209 0z"
      fill="#BCBCBC"
    />
  </svg>
);
