export const fetcher = (url: string) => fetch(url).then((res) => res.json())

export const userFetcher = async (url: string) => {
  const userData = localStorage.getItem('userData') || '{}'
  const userDateArray = JSON.parse(userData)

  const res = await fetch(url, {
    headers: {
      authorization: `Bearer ${userDateArray.token}`,
      'Content-Type': 'application/json',
    },
  })
  return res.json()
}
