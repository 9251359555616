"use client";
import useSWR from "swr";
import { BoltIcon } from "../icon/BoltIcon";
import { config } from "@/config";
import { fetcher } from "../fetcher";
import { Link } from "@/lib/i18n";
import * as m from "@/paraglide/messages.js";

export const Trends = () => {
  const { data: trendsList, error } = useSWR(
    `${config.apiUrl}/trends`,
    fetcher,
    {
      refreshWhenHidden: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  if (error) return <div>failed to load</div>;

  if (!trendsList) {
    return null;
  }

  const limitedList = trendsList.results.slice(0, 5);

  return (
    <div className="flex flex-row gap-2 mt-2 text-white">
      <div className="flex flex-row gap-1 items-center flex-grow-0 h-[22px]">
        <BoltIcon />
        <div className="italic text-sm">{m.search_trends()}:</div>
      </div>

      <div className="flex flex-col">
        {limitedList.map((movie: any) => {
          const link = `subtitle/${movie.sd_id}/${movie.slug}`;
          return (
            <Link href={link} key={movie.sd_id} prefetch={false}>
              <div className="flex flex-row gap-1">
                <div className="text-white text-sm [text-shadow:_0_2px_2px_rgb(0_0_0_/_50%)]">
                  {movie.name}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
