"use client";

import { Spacer } from "../lego/Spacer";
import { useI18n } from "../useI18n";
import { SearchInput } from "./SearchInput";
import * as m from "@/paraglide/messages.js";
import { Trends } from "./trends";

export const SearchBox = () => {
  const { isRTL, fontClassName } = useI18n();
  return (
    <div
      className="flex flex-col"
      style={{
        maxWidth: 600,
        marginLeft: isRTL ? 0 : 25,
        direction: isRTL ? "rtl" : "ltr",
        marginRight: isRTL ? 25 : 0,
      }}
    >
      <h1
        className={`font-bold text-left text-white ${fontClassName}`}
        style={{
          fontSize: 30,
          textShadow: "0px 3px 5px rgba(0, 0, 0, 1)",
          textAlign: isRTL ? "right" : "left",
          // fontFamily: isRTL ? "var(--rtl-font)" : "var(--font-sans)",
          // mixBlendMode: "difference",
          // color: "white",
          // filter: "drop-shadow(.05em .05em orange)",
        }}
      >
        {m.index_title()}
      </h1>
      <Spacer y={20} />
      <SearchInput />
      <Trends />
    </div>
  );
};
