"use client";
import React, { useState, useEffect } from "react";

const backgrounds = [
  // "/bgs/week11/c.jpg",
  // "/bgs/week11/d.jpg",
  // "/bgs/week11/e.jpg",
  // "/bgs/week11/f.jpg",
  // "/bgs/week11/g.jpg",
  "/bgs/week1/a.webp",
  "/bgs/week1/b.webp",
  "/bgs/week1/c.webp",
  "/bgs/week1/d.webp",
  "/bgs/week1/e.webp",
  "/bgs/week1/f.webp",
  "/bgs/week1/g.webp",
  "/bgs/week1/h.webp",
];

export const MainBg = () => {
  const [bg, setBg] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const randomBg =
      backgrounds[Math.floor(Math.random() * backgrounds.length)];
    const img = new Image();
    img.src = randomBg;
    img.onload = () => {
      setBg(randomBg);
      setIsLoaded(true);
    };
  }, []);

  return (
    <div>
      <style jsx global>{`
        body {
          margin: 0;
          padding: 0;
          min-height: 100vh;
          background-color: #4c4c4c !important;
          transition: background-image 0.5s ease-in-out;
          background-position: center;
        }

        body::before {
          content: "";
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url(${bg}) no-repeat center center;
          background-size: cover;
          opacity: ${isLoaded ? 1 : 0};
          transition: opacity 0.5s ease-in-out;
          z-index: -1;
        }
      `}</style>
    </div>
  );
};
