"use client";

import { useI18n } from "@/components/useI18n";
import { SearchBox } from "./SearchBox";

export const MainSearchBoxWrapper = () => {
  const { isRTL, fontClassName } = useI18n();
  return (
    <div
      style={{
        display: "flex",
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      <div className={`${fontClassName} w-full`}>
        <SearchBox />
      </div>
    </div>
  );
};
